<template>
  <base-layout current="userApproval">
    <v-container>
      <v-card elevation="0">
        <v-card>
          <v-card-title>
            User List
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :items="userArray"
            class="elevation-1"
            :sort-by="['approval_status']"
            :sort-desc="[false]"
            :items-per-page="20"
            :custom-sort="customSort"
            :search="search"
            :headers="headers"
            :loading="isLoading"
          >
            <template v-slot:[`item.preferred_products`]="{ item }">
              <v-chip
                class="ma-1"
                v-for="product in item.preferred_products"
                :key="product"
                x-small
                pill
              >
                {{ product }}
              </v-chip>
            </template>
            <template v-slot:[`item.submitted_at`]="{ item }">
              <span>
                {{
                  item.submitted_at ? formatDateTime(item.submitted_at) : ""
                }}</span
              >
            </template>

            <template v-slot:[`item.industry`]="{ item }">
              <span>
                {{
                  item.industry === "Other"
                    ? item.industry_other
                      ? item.industry_other
                      : "Other"
                    : item.industry
                }}</span
              >
            </template>

            <template v-slot:[`item.job_title`]="{ item }">
              <span>
                {{
                  item.job_title == "Other"
                    ? item.job_title_other
                      ? item.job_title_other
                      : "Other"
                    : item.job_title
                }}</span
              >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div style="display: flex; align-items: center">
                <v-btn
                  small
                  class="ma-1"
                  @click="showConfirmationDialog('approved', item)"
                  v-if="!item.approval_status"
                  color="success"
                >
                  <v-icon dark> mdi-check </v-icon>
                </v-btn>
                <v-btn
                  small
                  class="ma-1"
                  @click="showConfirmationDialog('rejected', item)"
                  v-if="!item.approval_status"
                  color="error"
                >
                  <v-icon dark> mdi-cancel </v-icon>
                </v-btn>
                <span v-else class="text-capitalize">{{
                  item.approval_status
                }}</span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
      <v-dialog v-model="confirmationDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <v-card-text>
            Are you sure you want to
            {{ action == "approved" ? "approve" : "reject" }} this user?
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="cancelAction"
              class="text-capitalize rounded-pill"
              color="primary"
              >Cancel</v-btn
            >
            <v-btn
              @click="confirmAction"
              class="text-capitalize rounded-pill"
              color="success"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseLayout,
  },
  data() {
    return {
      users: [],
      search: "",
      confirmationDialog: false,
      action: false,
      isLoading: false,
      userToConfirm: null,
      headers: [
        { text: "Email", value: "email" },
        { text: "Company", value: "company" },
        { text: "Name", value: "name" },
        { text: "Submitted At", value: "submitted_at" },
        { text: "Job Title", value: "job_title" },
        { text: "Industry", value: "industry" },
        { text: "Preferred Products", value: "preferred_products" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    userArray() {
      return Object.entries(this.users).map(([email, userData]) => ({
        email,
        ...userData,
      }));
    },
  },
  created() {
    this.getDevRegistrations();
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      const field = sortBy[0];

      //custom sorting logic for approval_status
      if (field === "approval_status") {
        return items.sort((a, b) => {
          const valueA = this.getApprovalSortValue(a.approval_status);
          const valueB = this.getApprovalSortValue(b.approval_status);
          return (sortDesc[0] ? -1 : 1) * valueA.localeCompare(valueB);
        });
      }

      //default sorting logic
      return items.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];
        return (sortDesc[0] ? -1 : 1) * (valueA > valueB ? 1 : -1);
      });
    },

    getApprovalSortValue(approvalStatus) {
      //custom sorting logic based on approval_status
      if (typeof approvalStatus === "boolean") {
        return approvalStatus ? "1" : "0";
      } else if (typeof approvalStatus === "string") {
        return approvalStatus.toLowerCase();
      } else {
        return "2";
      }
    },

    formatDateTime(date) {
      var d = new Date(date),
        year = d.getFullYear(),
        month = ("0" + (d.getMonth() + 1)).slice(-2),
        day = ("0" + d.getDate()).slice(-2),
        hours = ("0" + d.getHours()).slice(-2),
        minutes = ("0" + d.getMinutes()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    ...mapActions(["GetDevRegistrations", "DevApproval"]),
    async getDevRegistrations() {
      this.isLoading = true;
      try {
        const response = await this.GetDevRegistrations();
        this.users = response;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching users:", error);
        this.isLoading = false;
      }
    },
    async confirmAction() {
      try {
        if (this.userToConfirm) {
          const payload = {
            email: this.userToConfirm.email,
            approval_status: this.action,
          };

          const response = await this.DevApproval(payload);

          if (response.result === "OK") {
            this.userToConfirm.approval_status = this.action;
          } else {
            console.error(`error ${this.action} user: ${response.reason}`);
          }

          this.confirmationDialog = false;
          this.action = "";
          this.userToConfirm = null;
        }
      } catch (error) {
        console.error(`error ${this.action} user:`, error);
      }
    },
    showConfirmationDialog(action, user) {
      this.action = action === "approved" ? "approved" : "rejected";
      this.userToConfirm = user;
      this.confirmationDialog = true;
    },
    cancelAction() {
      this.confirmationDialog = false;
      this.action = "";
      this.userToConfirm = null;
    },
  },
};
</script>
