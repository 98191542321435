<template>
  <base-layout current="apikeys">
    <v-container fluid>
      <v-card class="card-main" elevation="0" :loading="isLoading">
        <div v-if="has_subscription">
          <v-form>
            <v-snackbar
              :timeout="timeout"
              absolute
              right
              color="green"
              text
              v-model="snackbarCopy"
            >
              {{ text }}
            </v-snackbar>
            <v-row class="px-8 py-8">
              <v-col cols="10" xl="2" lg="2">
                <v-subheader class="text-h5">API Key</v-subheader>
              </v-col>
              <v-col cols="10" xl="4" lg="4">
                <v-text-field
                  id="api-key"
                  filled
                  class="rounded-0"
                  outlined
                  style="font-family: 'Courier New', monospace"
                  v-model="apiKey"
                  readonly
                ></v-text-field>
              </v-col>
              <v-icon medium :ripple="false" @click="copyKey('api-key')"
                >mdi-content-copy</v-icon
              >
            </v-row>

            <v-row class="px-8">
              <v-col cols="10" xl="2" lg="2">
                <v-subheader class="text-h5">Secret Key</v-subheader>
              </v-col>
              <v-col cols="10" xl="4" lg="4">
                <v-text-field
                  v-model="secretKey"
                  class="rounded-0"
                  filled
                  outlined
                  style="font-family: 'Courier New', monospace"
                  :append-icon="showSecret ? 'mdi-eye' : 'mdi-eye-off'"
                  readonly
                  id="secret-key"
                  :ripple="false"
                  :type="showSecret ? 'text' : 'password'"
                  @click:append="showSecret = !showSecret"
                ></v-text-field>
              </v-col>
              <v-icon :ripple="false" medium @click="copyKey('secret-key')"
                >mdi-content-copy</v-icon
              >
            </v-row>
          </v-form>
          <div
            v-if="
              userData != null &&
              (monthly_limit || subscribed_at || selected_plan)
            "
          >
            <v-divider style="border-style: dashed"></v-divider>

            <v-row class="px-8 py-8">
              <v-col md="4">
                <v-card elevation="0" max-width="400">
                  <v-list-item>
                    <v-list-item-content>
                      <v-subheader class="text-h5"
                        >Subscription Details</v-subheader
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <div class="px-4">
                    <v-list-item two-line v-if="monthly_limit">
                      <v-list-item-content>
                        <v-list-item-title>Product</v-list-item-title>
                        <v-list-item-subtitle
                          >FaceAssure - On Browser
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <a
                      class="px-4"
                      href="https://documentation.privately.eu/guides/faceassure"
                      target="_blank"
                      >Go to documentation<v-icon>mdi-open-in-new</v-icon></a
                    >
                    <v-list-item two-line v-if="selected_plan">
                      <v-list-item-content>
                        <v-list-item-title>Current Plan</v-list-item-title>
                        <v-list-item-subtitle
                          style="text-transform: capitalize"
                          >{{ selected_plan }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line v-if="subscribed_at">
                      <v-list-item-content>
                        <v-list-item-title>Subscribed At</v-list-item-title>
                        <v-list-item-subtitle>{{
                          subscribed_at
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line v-if="monthly_limit">
                      <v-list-item-content>
                        <v-list-item-title
                          >Monthly Request Limit</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          monthly_limit
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line v-if="selected_plan == 'sandbox'">
                      <v-list-item-content>
                        <v-list-item-title>Trial ends in </v-list-item-title>
                        <v-list-item-subtitle
                          >{{
                            remainingDaysForTrial()
                          }}
                          days</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card></v-col
              ><v-col
                ><v-card elevation="0" max-width="500">
                  <v-list-item>
                    <v-list-item-content>
                      <v-subheader class="text-h5"
                        >Usage Statistics</v-subheader
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content style="width: 500px">
                      <VueApexCharts
                        type="donut"
                        :options="chartOptions"
                        :series="chartSeries"
                      ></VueApexCharts>
                    </v-list-item-content>
                  </v-list-item> </v-card
              ></v-col>
            </v-row>

            <v-row class="px-8" v-if="selected_plan != 'sandbox'">
              <v-col md="4">
                <v-card elevation="0" max-width="300px">
                  <v-list-item>
                    <v-list-item-content style="width: 300px">
                      <v-btn
                        color="red"
                        outlined
                        @click="cancelSubscription()"
                        :loading="isCancelLoading"
                        >Cancel Subscription</v-btn
                      ></v-list-item-content
                    ></v-list-item
                  >
                </v-card></v-col
              >
            </v-row>

            <!-- <v-divider style="border-style: dashed"></v-divider> -->

            <v-row class="px-8 py-8"></v-row>
          </div>
        </div>
        <v-list-item v-else>
          <v-list-item-content v-if="!isLoading">
            <v-row>
              <v-col style="max-width: 600px">
                <v-alert outlined type="info"
                  >You have not subscribed to any plan
                  <v-btn
                    class="ml-6 text-capitalize"
                    outlined
                    text
                    color="primary"
                    to="/plans"
                    medium
                    >Explore plans</v-btn
                  ></v-alert
                >
              </v-col></v-row
            >
          </v-list-item-content>
        </v-list-item>

        <v-snackbar
          timeout="2000"
          absolute
          right
          color="green"
          text
          v-model="snackbar"
        >
          {{ snackbarText }}
        </v-snackbar>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
import VueApexCharts from "vue-apexcharts";
import { mapActions } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  async created() {
    //await new Promise((r) => setTimeout(r, 3000));
  },
  async beforeMount() {
    //await new Promise((r) => setTimeout(r, 3000));

    const User = new FormData();
    User.append("username", store.getters.StateUser);
    User.append("password", store.getters.StateUserPass);
    this.isLoading = true;
    await new Promise((r) => setTimeout(r, 2000));

    try {
      await this.LogIn(User).then(() => {
        this.isLoading = false;
      });
    } catch (e) {
      this.isLoading = false;
      console.log("error:", e);
    }

    this.userData = store.getters.StateUserData;
    this.apiKey = store.getters.StateSessionId;
    this.secretKey = store.getters.StateSessionPassword;
    this.additional_info_fields = this.userData.additional_info_fields;

    if (
      !Object.keys(this.additional_info_fields).length !== 0 &&
      Object.prototype.hasOwnProperty.call(
        this.additional_info_fields,
        "has_subscription"
      ) &&
      this.additional_info_fields["has_subscription"]
    ) {
      this.has_subscription = true;
      this.stripeSubscriptionId =
        this.additional_info_fields["stripe_subscription_id"];
      this.monthly_limit = this.additional_info_fields["monthly_limit"];
      this.subscribed_at = this.additional_info_fields["subscribed_at"];
      this.selected_plan = this.additional_info_fields["selected_plan"];
      this.cancelled_at = this.additional_info_fields["cancelled_at"];

      const fmt = moment(this.subscribed_at);
      this.subscribed_at = fmt.format("YYYY-MM-DD");

      await this.getAnalyticsSummary();
      this.updateChart();
      console.log("all transaction count:", this.allTransactionCount);
    } else if (
      !Object.keys(this.additional_info_fields).length !== 0 &&
      Object.prototype.hasOwnProperty.call(
        this.additional_info_fields,
        "cancelled_at"
      ) &&
      this.additional_info_fields["cancelled_at"]
    ) {
      console.log("cancelled plan");
    } else {
      this.has_subscription = false;
      //NO SUBSCRIPTION
    }
  },
  components: {
    BaseLayout,
    VueApexCharts,
  },
  methods: {
    ...mapActions(["AnalyticsRangeSummary", "LogIn"]),
    async cancelSubscription() {
      this.isCancelLoading = true;
      try {
        const payload = {
          subscriptionId: this.stripeSubscriptionId,
          env: this.baseDomain.includes("localhost") ? "test" : "prod",
        };

        await axios.post(
          "https://xunbx8l3o9.execute-api.eu-west-1.amazonaws.com/default/devchannel-stripe-subscriptionmanagement",
          payload
        );

        this.isCancelLoading = false;
        this.snackbarText = "Your subscription has been cancelled";
        this.snackbar = true;
        window.location.reload();
        //this.$router.push("/api-keys");
        //await this.init();
      } catch (error) {
        console.error("Error canceling subscription:", error);
        this.snackbarText = "Error on cancelling subscription: " + error;
        this.isCancelLoading = false;
      }
    },
    copyKey(key) {
      let keyToCopy = document.querySelector("#" + key);
      keyToCopy.setAttribute("type", "text");
      keyToCopy.select();

      try {
        document.execCommand("copy");
        if (key == "secret-key") {
          keyToCopy.setAttribute("type", "password");
        }
        this.snackbarCopy = true;
      } catch (err) {
        console.log(err);
      }

      window.getSelection().removeAllRanges();
    },
    updateChart() {
      const remainingCount = this.monthly_limit - this.allTransactionCount;
      this.chartSeries = [this.allTransactionCount, remainingCount];
    },
    remainingDaysForTrial() {
      const subscribedDate = moment(this.subscribed_at);
      const today = moment();
      const daysRemaining = 30 - today.diff(subscribedDate, "days");

      return Math.max(0, daysRemaining);
    },
    async getAnalyticsSummary() {
      const rawSubscribedAt = this.additional_info_fields["subscribed_at"];

      const subscribedAt = moment(rawSubscribedAt);

      if (!subscribedAt.isValid()) {
        console.error("Invalid date format:", rawSubscribedAt);
        return;
      }

      const currentDate = moment();
      let billingStartDate = subscribedAt.clone();
      while (billingStartDate.isBefore(currentDate)) {
        billingStartDate.add(30, "days");
      }
      billingStartDate.subtract(30, "days");

      const billingEndDate = billingStartDate
        .clone()
        .add(30, "days")
        .endOf("day");

      const formattedFrom = billingStartDate.format("YYYY-MM-DD");
      const formattedUntil = billingEndDate.format("YYYY-MM-DD");

      const payload = {
        session_id: this.apiKey,
        from: formattedFrom,
        until: formattedUntil,
      };

      try {
        const res = await this.AnalyticsRangeSummary(payload);
        if (res) {
          this.allTransactionCount = res["all_transaction_count"];
        }
      } catch (error) {
        console.error("Error fetching analytics summary:", error);
      }
    },
  },
  data() {
    return {
      isLoading: false,
      apiKey: "",
      secretKey: "",
      cancelled_at: null,
      selected_plan: "",
      subscribed_at: "",
      isCancelLoading: false,
      stripeSubscriptionId: null,

      monthly_limit: "",
      allTransactionCount: 0,
      userData: "",
      has_subscription: false,
      additional_info_fields: {},
      showSecret: false,
      snackbar: false,
      snackbarCopy: false,
      baseDomain: window.location.origin,
      snackbarText: "",
      text: "Copied!",
      timeout: 2000,
      sessionDetails: "",
      chartOptions: {
        chart: {
          type: "donut",
          width: 700,
          height: 300,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: ["Used Transactions", "Remaining Transactions"],
      },
      chartSeries: [],
    };
  },
};
</script>