<template>
  <v-app style="background: #fafafa">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="6"
          ><v-alert outlined type="success" text>
            Congratulations! Your payment was successful, and your 7-day free
            trial has started! Thank you for choosing AgeAI for Retail.
            <br /><br />Look out for an email with instructions about your newly
            created licenses. If you have any questions, feel free to reach out
            to our support team at
            <a href="mailto:contact@privately.eu">contact@privately.eu</a>.
          </v-alert></v-col
        >
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import FooterItem from "@/components/FooterItem.vue";

export default {
  created() {},
  mounted() {
    this.init();
  },
  components: { FooterItem },
  computed: {
    getTotalNumberOfLicences: function () {
      return this.totalNumberOfLicences;
    },
    getHeadersDynamically: function () {
      let headers = [
        {
          text: "Licence code",
          align: "start",
          sortable: false,
          value: "licence_code",
        },
        { text: "Created at", value: "licence_created_at_utc" },
        { text: "Status", value: "activated_at_utc" },
        { text: "Requested by", value: "requested_by" },
        { text: "Subclient", value: "subclient" },
        { text: "Location", value: "location" },
        { text: "QR Code | Deeplink", sortable: false, value: "deeplink_data" },
      ];

      if (this.selfService) {
        headers.pop();
        headers.push({ text: "Billed to", value: "billed_to_email" });
      }

      return headers;
    },
  },
  methods: {
    ...mapActions(["ListLicencesByBillingGroupId"]),
    onQRClicked(item) {
      this.dialogForQRCode = true;
      this.selectedDeeplinkData = item["deeplink_data"];
      this.selectedLicenceCode = item["licence_code"];
      //start polling for activation
      this.pollForLicenceActivation();
    },
    flattenObject(obj) {
      const flattened = {};

      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          Object.assign(flattened, this.flattenObject(value));
        } else {
          flattened[key] = value;
        }
      });

      return flattened;
    },
    pollForLicenceActivation() {
      const pollInterval = 2000;
      const maxPollAttempts = 15;

      let pollCount = 0;
      const poll = setInterval(() => {
        pollCount++;
        this.ListLicencesByBillingGroupId({
          billing_group_id: this.token,
        })
          .then((response) => {
            if (response.msg === "OK") {
              const updatedLicence = response.licences.find(
                (licence) => licence.licence_code === this.selectedLicenceCode
              );

              if (updatedLicence && updatedLicence.activated_at_utc) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
                this.licenceList = response.licences;
                this.init();
                this.snackbar = true;
                this.text = `Licence with code ${this.selectedLicenceCode} has been activated successfully`;
              }

              if (pollCount >= maxPollAttempts || !this.dialogForQRCode) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
              }
            }
          })
          .catch((error) => {
            clearInterval(poll);
            console.error("Error occurred during polling: ", error);
          });
      }, pollInterval);
    },
    init() {
      this.isLoading = true;
      this.flattenedList = [];
      this.licenceList = [];
      this.ListLicencesByBillingGroupId({
        billing_group_id: this.token,
      })
        .then((response) => {
          if (response.msg == "OK") {
            this.errorOccurred = false;
            this.licenceList = response.licences;
            this.numberOfActivatedLicences = this.licenceList.filter(
              (licence) => licence.activated_at_utc != false
            ).length;
            this.totalNumberOfLicences = this.licenceList.length;
            this.numberOfInactiveLicences =
              this.totalNumberOfLicences - this.numberOfActivatedLicences;
            for (let licence of this.licenceList) {
              this.flattenedList.push(this.flattenObject(licence));
            }
          }
        })
        .catch((error) => {
          console.log("error occurred: ", error);
          this.errorOccurred = true;
          this.text = error;
          this.snackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
      deep: false,
    },
  },

  data() {
    return {
      options: {},
      search: "",

      token: this.$route.params.token,
      isLoading: false,
      licenceList: [],
      item: null,
      snackbar: false,
      snackbarTimeout: 5000,

      flattenedList: [],
      numberOfLicences: 0,
      numberOfActivatedLicences: 0,
      numberOfInactiveLicences: 0,
      totalNumberOfLicences: 0,
      errorOccurred: false,
      dialogForQRCode: false,
      selectedLicenceCode: "",
      selectedDeeplinkData: "",
      text: " licence(s) have been generated successfully!",

      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
    };
  },
};
</script>
