<template>
  <base-layout current="activatelicence">
    <v-container>
      <v-card elevation="0">
        <v-container fill-height fluid>
          <v-row class="ma-4" justify="center" align="center">
            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Total number of licences"
                :metricValue="getTotalNumberOfLicences"
              />
            </v-col>
            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Active"
                :metricValue="numberOfActivatedLicences"
              />
            </v-col>
            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Inactive"
                :metricValue="numberOfInactiveLicences"
              />
            </v-col>
            <v-col>
              <EstimationCard
                :loading="isLoading"
                metric-title="Pass Rate (Last 7 Days)"
                :metricValue="passRate + '%'"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          color="green"
          dark
          class="text-capitalize ml-3"
          @click="dialogForLicenceGeneration = true"
        >
          Generate licence <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-container>
          <v-data-table
            :loading="isLoading"
            loading-text="Loading... Please wait"
            :headers="getHeadersDynamically"
            :items="filteredLicences"
            :items-per-page="10"
            :search="search"
            :custom-filter="advancedFilter"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-toolbar-title class="text-h6 font-weight-medium">
                    AgeAI Licences
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-text-field
                    style="max-width: 150px"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    dense
                    class="mr-4"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-autocomplete
                    v-model="selectedStatus"
                    :items="statusOptions"
                    item-text="label"
                    item-value="value"
                    label="Status"
                    style="max-width: 185px"
                    clearable
                    dense
                    hide-no-data
                    hide-details
                    combobox
                    class="mx-2"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        v-if="item"
                        small
                        :color="item.chipColor"
                        dark
                        close
                        @click:close="selectedStatus = null"
                      >
                        {{ item.label }}
                      </v-chip>
                    </template>

                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-chip small dark class="ma-2" :color="item.chipColor">
                          {{ item.label }}
                        </v-chip>
                      </v-list-item>
                    </template>
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedStoreChain"
                    :items="storeChainOptions"
                    label="Store-Chain"
                    clearable
                    dense
                    hide-no-data
                    hide-details
                    style="max-width: 150px"
                    class="mx-2 custom-filter"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="selectedStore"
                    :items="storeOptions"
                    label="Store"
                    clearable
                    dense
                    hide-no-data
                    style="max-width: 100px"
                    hide-details
                    class="mx-2"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="selectedCounterLocation"
                    :items="counterLocationOptions"
                    label="Counter Location"
                    clearable
                    dense
                    hide-no-data
                    style="max-width: 165px"
                    hide-details
                    class="mx-2"
                  ></v-autocomplete>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.requested_by`]="{ item }">
              <v-hover v-slot="{ hover }">
                <div class="d-flex align-center">
                  <v-edit-dialog
                    :return-value.sync="item.requested_by"
                    large
                    persistent
                    @save="updateLicenceDetails(item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    <div>
                      {{ item.requested_by }}
                      <v-icon
                        small
                        :style="
                          hover ? 'visibility:visible;' : 'visibility:hidden;'
                        "
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Store-Chain</div>
                      <v-text-field
                        v-model="item.requested_by"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </v-hover>
            </template>
            <template v-slot:[`item.subclient`]="{ item }">
              <v-hover v-slot="{ hover }">
                <div class="d-flex align-center">
                  <v-edit-dialog
                    :return-value.sync="item.subclient"
                    large
                    persistent
                    @save="updateLicenceDetails(item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    <div>
                      {{ item.subclient }}
                      <v-icon
                        small
                        :style="
                          hover ? 'visibility:visible;' : 'visibility:hidden;'
                        "
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Store</div>
                      <v-text-field
                        v-model="item.subclient"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </v-hover>
            </template>
            <template v-slot:[`item.location`]="{ item }">
              <v-hover v-slot="{ hover }">
                <div class="d-flex align-center">
                  <v-edit-dialog
                    :return-value.sync="item.location"
                    large
                    persistent
                    @save="updateLicenceDetails(item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    <div>
                      {{ item.location }}
                      <v-icon
                        small
                        :style="
                          hover ? 'visibility:visible;' : 'visibility:hidden;'
                        "
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Counter Location</div>
                      <v-text-field
                        v-model="item.location"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </v-hover>
            </template>
            <template v-slot:[`item.licence_code`]="{ item }">
              <span class="d-flex align-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        :color="deviceLivenessStatusColor(item.last_seen_utc)"
                      >
                        mdi-access-point
                      </v-icon>
                    </span>
                  </template>
                  <span
                    >Last seen:
                    {{
                      !item.last_seen_utc ? "None" : item.last_seen_utc
                    }}</span
                  >
                </v-tooltip>
                &nbsp;
                <div>{{ item.licence_code }}</div>
              </span>
            </template>
            <template v-slot:[`item.activated_at_utc`]="{ item }">
              <div class="text-center">
                <v-chip
                  small
                  :color="item.activated_at_utc ? 'blue' : 'green'"
                  dark
                  :style="{
                    padding: '8px',
                    padding: item.activated_at_utc ? '18px' : '8px',
                  }"
                >
                  <div v-if="item.activated_at_utc" class="chip-content">
                    Activated<br />at {{ item.activated_at_utc }}
                  </div>
                  <div v-else>Not activated</div>
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.device_model`]="{ item }">
              <div v-if="!item.device_model">-</div>
              <div v-else>{{ item.device_model }}</div>
            </template>
            <template v-slot:[`item.deeplink_data`]="{ item }">
              <div style="display: flex; align-items: center">
                <v-btn
                  color="black"
                  plain
                  :disabled="item.activated_at_utc != null"
                  @click="onQRClicked(item)"
                  text
                >
                  <v-icon dense>mdi-qrcode</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list class="d-flex flex-column align-center text-center">
                  <v-list-item>
                    <v-list-item @click="copyDeeplink(item.deeplink_data)">
                      Copy deeplink <v-icon>mdi-content-copy</v-icon>
                    </v-list-item>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      apiKey == '2046e948-377c-4fc0-8a70-1e36adf0f3a5' ||
                      apiKey == 'fdbb2a02-3d1a-4b78-a05b-f7cc069ade8c' ||
                      apiKey == '62b45980-32f6-4a2d-8fc1-b24f4793db8a'
                    "
                    :disabled="!item.activated_at_utc"
                    @click="
                      itemToBeDetached = item;
                      detachConfirmationDialog = true;
                    "
                  >
                    Detach licence <v-icon>mdi-link-variant-remove</v-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-snackbar
            :timeout="snackbarTimeout"
            absolute
            top
            outlined
            :color="!errorOccurred ? 'success' : 'red'"
            v-model="snackbar"
          >
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card>
      <v-dialog v-model="detachConfirmationDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Detach Confirmation</v-card-title>
          <v-card-text>
            Are you sure you want to detach the licence
            <b>{{ itemToBeDetached.licence_code }}</b> from its device
            <b>{{ itemToBeDetached.device_model }}</b
            >?
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="detachConfirmationDialog = false"
              class="text-capitalize rounded-pill"
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="confirmDetach(itemToBeDetached.deeplink_data)"
              class="text-capitalize rounded-pill"
              color="success"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogForQRCode" width="350">
        <v-card>
          <v-card-title class="subtitle-1 grey lighten-2">
            <span class="font-weight-bold">Licence code:&nbsp;</span>
            {{ selectedLicenceCode }}
            <br />
            <span style="overflow-wrap: break-word; word-break: break-word">
              Please scan the QR code below to activate your
              <span class="font-weight-bold">AgeAI for Retail</span> licence
            </span>
          </v-card-title>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col>
                <v-card-text>
                  <qrcode-vue
                    :value="
                      'https://developer.privately.eu/retail-app-activation/' +
                      selectedDeeplinkData
                    "
                    :size="300"
                    level="H"
                  />
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogForLicenceGeneration" max-width="500px">
        <v-card :loading="loadingForLicenceGeneration">
          <v-card-title>
            <span class="text-h5">Generate new licences</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-combobox
                      v-model="formInput.requested_by"
                      :items="storeChainList"
                      label="Store-Chain"
                      clearable
                      dense
                      hide-no-data
                      hide-details
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                      style="max-width: 185px"
                      class="mx-2"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.subclient"
                      label="Store"
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.location"
                      :rules="[(v) => !!v || 'This field is required']"
                      label="Counter Location"
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="formInput.billed_to_email"
                      outlined
                      v-if="selfService"
                      dense
                      :rules="[
                        (v) => !!v || 'This field is required',
                        (v) => emailRegex.test(v) || 'Invalid Email address',
                      ]"
                      label="Billed to (email)"
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="formInput.quantity"
                      :rules="[
                        (v) => !!v || 'This field is required',
                        (v) =>
                          (v && v <= 50 && v >= 1) ||
                          'Quantity must be between 1 and 50',
                      ]"
                      label="Quantity"
                      type="number"
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-select
                      v-model="formInput.price_per_licence"
                      :items="priceList"
                      outlined
                      v-if="selfService"
                      dense
                      item-text="priceName"
                      item-value="priceVal"
                      :rules="[(v) => !!v.priceVal || 'Please select a price']"
                      label="Price per licence"
                      return-object
                      single-line
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="formInput.subscription_period"
                      v-if="selfService"
                      :rules="[(v) => !!v || 'Please select a period']"
                      column
                      required
                    >
                      <template v-slot:label>
                        <div style="font-size: 16px">
                          Subscription Period: &nbsp;
                        </div>
                      </template>
                      <v-radio label="Monthly" value="month"></v-radio>
                      <v-radio label="Yearly" value="year"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-row
                    v-if="
                      formInput.quantity > 0 &&
                      formInput.quantity <= 50 &&
                      formInput.price_per_licence.priceVal &&
                      formInput.subscription_period != ''
                    "
                    align="center"
                  >
                    <v-col cols="6">
                      <v-subheader style="font-size: 16px"
                        >Estimated price:</v-subheader
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        readonly
                        :value="getEstimatedPrice"
                        prefix="$"
                      />
                    </v-col>
                  </v-row>
                  <v-col></v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogForLicenceGeneration = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="loadingForLicenceGeneration"
              color="green darken-1"
              dark
              @click="generateLicences"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
import QrcodeVue from "qrcode.vue";
import { mapActions } from "vuex";
import EstimationCard from "@/components/cards/EstimationCard.vue";

export default {
  beforeMount() {
    this.user = store.getters.StateUser;
    this.userData = store.getters.StateUserData;
    this.apiKey = store.getters.StateSessionId;
    this.secretKey = store.getters.StateSessionPassword;
    this.sessionDetails = store.getters.StateSessionDetails;
  },
  mounted() {
    this.init();
  },
  components: {
    BaseLayout,
    QrcodeVue,
    EstimationCard,
  },
  methods: {
    ...mapActions([
      "ListLicences",
      "GenerateLicences",
      "DetachLicence",
      "UpdateLicenceDetails",
    ]),
    init() {
      this.isLoading = true;
      this.flattenedList = [];
      this.licenceList = [];
      this.ListLicences({
        session_id: this.apiKey,
        session_password: this.secretKey,
      })
        .then((response) => {
          if (response.msg === "OK") {
            this.errorOccurred = false;

            const flattenedLicences = response.licences.map((lic) => {
              return this.flattenObject(lic);
            });
            this.licenceList = flattenedLicences;
            this.flattenedList = flattenedLicences;

            this.storeChainList = [
              ...new Set(this.licenceList.map((l) => l.requested_by)),
            ];

            this.storeChainOptions = [
              ...new Set(this.licenceList.map((l) => l.requested_by)),
            ];
            this.storeOptions = [
              ...new Set(this.licenceList.map((l) => l.subclient)),
            ];
            this.counterLocationOptions = [
              ...new Set(this.licenceList.map((l) => l.location)),
            ];

            this.numberOfActivatedLicences = this.licenceList.filter(
              (licence) => licence.activated_at_utc != null
            ).length;

            this.passRate =
              response.pass_rates_7d && response.pass_rates_7d > 0
                ? (response.pass_rates_7d * 100).toFixed(0)
                : 0;

            this.totalNumberOfLicences = this.licenceList.length;
            this.numberOfInactiveLicences =
              this.totalNumberOfLicences - this.numberOfActivatedLicences;
          }
        })
        .catch((error) => {
          this.errorOccurred = true;
          this.text = error;
          this.snackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    async generateLicences() {
      if (this.validate() === true) {
        this.loadingForLicenceGeneration = true;
        const generateLicencesPayload = {
          session_id: this.apiKey,
          session_password: this.secretKey,
          quantity: parseInt(this.formInput.quantity),
          requested_by: this.formInput.requested_by,
          subclient: this.formInput.subclient,
          location: this.formInput.location,
        };
        if (this.selfService) {
          generateLicencesPayload["billed_to_email"] =
            this.formInput.billed_to_email;
          generateLicencesPayload["price_per_licence"] = parseFloat(
            this.formInput.price_per_licence.priceVal
          );
          generateLicencesPayload["pricing_currency"] = "USD";
          generateLicencesPayload["reseller_email"] = this.user;
          generateLicencesPayload["subscription_settings"] = {
            subscription_period: this.formInput.subscription_period,
            baseDomain: this.baseDomain,
            env: this.baseDomain.includes("localhost") ? "test" : "prod",
          };
        }
        this.GenerateLicences(generateLicencesPayload)
          .then((response) => {
            if (response) {
              const currentLicenceListLength = this.licenceList.length;
              const pollForLicenceListUpdates = () => {
                this.ListLicences({
                  session_id: this.apiKey,
                  session_password: this.secretKey,
                })
                  .then((response) => {
                    if (response.msg === "OK") {
                      const updatedLicenceListLength = response.licences.length;
                      if (updatedLicenceListLength > currentLicenceListLength) {
                        this.licenceList = response.licences;
                        this.text =
                          generateLicencesPayload.quantity +
                          " licence(s) have been generated successfully";
                        this.snackbar = true;
                        this.loadingForLicenceGeneration = false;
                        this.dialogForLicenceGeneration = false;
                        this.init();
                      } else {
                        setTimeout(pollForLicenceListUpdates, 1000);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    clearInterval();
                  });
              };
              pollForLicenceListUpdates();
            }
          })
          .catch((error) => {
            this.errorOccurred = true;
            this.text = "Error occurred: " + error;
            this.snackbar = true;
            this.loadingForLicenceGeneration = false;
          });
      }
    },
    updateLicenceDetails(item) {
      this.isLoading = true;
      this.UpdateLicenceDetails({
        deeplink_data: item.deeplink_data,
        location: item.location,
        subclient: item.subclient,
        requested_by: item.requested_by,
      })
        .then(() => {
          this.isLoading = false;
          this.text = "Licence details updated successfully!";
          this.errorOccurred = false;
          this.snackbar = true;
        })
        .catch(() => {
          this.isLoading = false;
          this.text = "Failed to update licence details.";
          this.errorOccurred = true;
          this.snackbar = true;
        });
    },
    copyDeeplink(url) {
      const fullUrl = `https://developer.privately.eu/retail-app-activation/${url}`;
      const tempInput = document.createElement("input");
      tempInput.value = fullUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand("copy");
        this.text = "URL copied!";
        this.errorOccurred = false;
        this.snackbar = true;
      } catch (err) {
        this.text = "Failed to copy URL.";
        this.errorOccurred = true;
        this.snackbar = true;
      }
      document.body.removeChild(tempInput);
    },
    onQRClicked(item) {
      this.dialogForQRCode = true;
      this.selectedDeeplinkData = item["deeplink_data"];
      this.selectedLicenceCode = item["licence_code"];
      this.pollForLicenceActivation();
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    deviceLivenessStatusColor(date) {
      if (!date) {
        return "error";
      }
      const currentLocalTime = new Date();
      const currentUTCTime = new Date(
        currentLocalTime.getTime() +
          currentLocalTime.getTimezoneOffset() * 60000
      );
      const diffMilliseconds =
        new Date(date).getTime() - currentUTCTime.getTime();
      const diffHours = Math.abs(diffMilliseconds / (1000 * 60 * 60));
      if (diffHours >= 1) {
        return "warning";
      } else {
        return "success";
      }
    },
    confirmDetach(deeplink_data) {
      this.detachConfirmationDialog = false;
      this.isLoading = true;
      this.DetachLicence(deeplink_data).then(() => {
        this.init();
      });
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {},
    pollForLicenceActivation() {
      const pollInterval = 2000;
      const maxPollAttempts = 15;
      let pollCount = 0;
      const poll = setInterval(() => {
        pollCount++;
        this.ListLicences({
          session_id: this.apiKey,
          session_password: this.secretKey,
        })
          .then((response) => {
            if (response.msg === "OK") {
              const updatedLicence = response.licences.find(
                (licence) => licence.licence_code === this.selectedLicenceCode
              );
              if (updatedLicence && updatedLicence.activated_at_utc) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
                this.licenceList = response.licences;
                this.init();
                this.snackbar = true;
                this.text = `Licence with code ${this.selectedLicenceCode} has been activated successfully`;
              }
              if (pollCount >= maxPollAttempts || !this.dialogForQRCode) {
                clearInterval(poll);
                this.isQRScanned = false;
                this.dialogForQRCode = false;
              }
            }
          })
          .catch(() => {
            clearInterval(poll);
          });
      }, pollInterval);
    },
    flattenObject(obj) {
      const flattened = {};
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          Object.assign(flattened, this.flattenObject(value));
        } else {
          flattened[key] = value;
        }
      });
      return flattened;
    },
    advancedFilter(value, search, item) {
      const rowString = Object.values(item).join(" ").toLowerCase();
      if (search && !rowString.includes(search.toLowerCase())) {
        return false;
      }
      if (
        this.selectedStoreChain &&
        item.requested_by !== this.selectedStoreChain
      ) {
        return false;
      }
      if (this.selectedStore && item.subclient !== this.selectedStore) {
        return false;
      }
      if (this.selectedStore && item.location !== this.selectedStore) {
        return false;
      }
      return true;
    },
  },
  computed: {
    filteredLicences() {
      return this.flattenedList.filter((item) => {
        const matchesSearch = this.search
          ? Object.values(item)
              .join(" ")
              .toLowerCase()
              .includes(this.search.toLowerCase())
          : true;
        const matchesStoreChain = this.selectedStoreChain
          ? item.requested_by === this.selectedStoreChain
          : true;
        const matchesStore = this.selectedStore
          ? item.subclient === this.selectedStore
          : true;
        const matchesCounterLocation = this.selectedCounterLocation
          ? item.location === this.selectedCounterLocation
          : true;
        const matchesStatus = this.selectedStatus
          ? this.selectedStatus === "activated"
            ? !!item.activated_at_utc
            : !item.activated_at_utc
          : true;
        return (
          matchesSearch &&
          matchesStoreChain &&
          matchesStore &&
          matchesCounterLocation &&
          matchesStatus
        );
      });
    },
    getTotalNumberOfLicences() {
      return this.totalNumberOfLicences;
    },
    getEstimatedPrice() {
      if (this.formInput.subscription_period === "month") {
        return (
          this.formInput.quantity * this.formInput.price_per_licence.priceVal +
          " / Month"
        );
      } else if (this.formInput.subscription_period === "year") {
        return (
          this.formInput.quantity *
            this.formInput.price_per_licence.priceVal *
            12 +
          " / Year"
        );
      } else return 0;
    },
    priceList() {
      let priceList = [
        { priceName: "$67.5 (10% discount)", priceVal: "67.5" },
        { priceName: "$56 (25% discount)", priceVal: "56" },
        { priceName: "$45 (40% discount)", priceVal: "45" },
        { priceName: "$37.5 (50% discount)", priceVal: "37.5" },
      ];
      if (this.formInput.quantity < 10) {
        priceList.pop();
      }
      return priceList;
    },
    getHeadersDynamically() {
      let headers = [
        {
          text: "Licence code",
          align: "start",
          sortable: false,
          value: "licence_code",
        },
        { text: "Created at", value: "licence_created_at_utc" },
        { text: "Status", value: "activated_at_utc" },
        { text: "Store-Chain", value: "requested_by" },
        { text: "Store", value: "subclient" },
        { text: "Counter Location", value: "location" },
        { text: "Device model", value: "device_model" },
        { text: "QR Code", sortable: false, value: "deeplink_data" },
        { text: "Actions", value: "actions" },
      ];
      if (this.selfService) {
        headers.pop();
        headers.push({ text: "Billed to", value: "billed_to_email" });
      }
      return headers;
    },
  },
  data() {
    return {
      storeChainList: [],
      search: "",
      user: "",
      apiKey: "",
      emailRegex:
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      secretKey: "",
      options: {},
      userData: "",
      isLoading: false,
      billing_group_id: "",
      licenceList: [],
      numberOfLicences: 0,
      numberOfActivatedLicences: 0,
      numberOfInactiveLicences: 0,
      passRate: 0,
      item: null,
      snackbar: false,
      text: "",
      snackbarTimeout: 2000,
      sessionDetails: "",
      flattenedList: [],
      baseDomain: window.location.origin,
      dialogForQRCode: false,
      selectedLicenceCode: "",
      detachConfirmationDialog: false,
      itemToBeDetached: "",
      selectedDeeplinkData: "",
      errorOccurred: false,
      formInput: {
        quantity: "",
        requested_by: "",
        subclient: "",
        location: "",
        billed_to_email: "",
        price_per_licence: {},
        subscription_period: "",
        pricing_currency: "USD",
      },
      dialogForLicenceGeneration: false,
      loadingForLicenceGeneration: false,
      valid: true,
      totalNumberOfLicences: 0,
      selfService: false,
      selectedStoreChain: null,
      selectedStore: null,
      selectedCounterLocation: null,
      storeChainOptions: [],
      counterLocationOptions: [],
      storeOptions: [],
      selectedStatus: null,
      statusOptions: [
        { label: "Activated", value: "activated", chipColor: "blue" },
        { label: "Not activated", value: "not_activated", chipColor: "green" },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
      deep: false,
    },
  },
};
</script>

<style scoped>
.card-main {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.reset-text-transform {
  text-transform: none !important;
}
</style>
