<template>
  <v-app style="background: #fafafa">
    <v-container fluid fill-height>
      <!-- <v-btn
        :to="home_route"
        color="#18739d"
        style="text-transform: unset !important"
        outlined
        absolute
        top
        left
        small
      >
        <v-icon> mdi-format-horizontal-align-left </v-icon>Back to home
      </v-btn> -->

      <v-row justify="center">
        <v-col cols="12">
          <v-alert
            dense
            v-if="isActivated"
            class="login-alert"
            outlined
            text
            type="success"
            >Account activated. Now you can log in.
          </v-alert>
          <v-alert
            dense
            v-if="showError"
            class="login-alert"
            outlined
            text
            type="error"
            >{{ errorMsg }}
          </v-alert>

          <v-row justify="center"> </v-row>
          <v-card elevation="0" class="login-card">
            <v-row justify="center" class="py-8"
              ><img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
            /></v-row>

            <v-form ref="form" v-model="valid">
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="username"
                  v-model="username"
                  label="Email Address"
                  :rules="emailRules"
                  required
                  :disabled="isFirstTimeLogin"
                  @keyup.enter="submit"
                ></v-text-field>
                <v-text-field
                  outlined
                  :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                  dense
                  :type="showIcon ? 'text' : 'password'"
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="password"
                  v-model="password"
                  @click:append="showIcon = !showIcon"
                  label="Password"
                  required
                  @keyup.enter="submit"
                ></v-text-field>
                <div v-if="isFirstTimeLogin">
                  <p
                    v-if="!$v.password.required"
                    id="error"
                    class="text-error-message"
                  >
                    Password is required
                  </p>
                  <p
                    v-if="password && !$v.password.maxLength"
                    id="error"
                    class="text-error-message"
                  >
                    Password must be maximum 16 characters
                  </p>
                  <p
                    v-if="password && !$v.password.valid"
                    id="error"
                    class="text-error-message"
                  >
                    Your password must be 8-16 characters, and include at least
                    one lowercase letter, one uppercase letter, and a number and
                    a special character
                  </p>
                  <p
                    v-if="
                      password && $v.password.valid && !$v.password.minLength
                    "
                    id="error"
                    class="text-error-message"
                  >
                    Password must be minimum 8 characters
                  </p>
                  <v-text-field
                    outlined
                    :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                    dense
                    :type="showIcon ? 'text' : 'password'"
                    background-color="#FAFAFA"
                    class="rounded-0"
                    ref="confirmPassword"
                    v-model="confirmPassword"
                    @click:append="showIcon = !showIcon"
                    label="Confirm Password"
                    required
                    @keyup.enter="submit"
                  ></v-text-field>
                  <p
                    v-if="!$v.confirmPassword.sameAsPassword"
                    id="error"
                    class="text-error-message"
                  >
                    Passwords must be identical.
                  </p>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#18739d"
                  text
                  v-if="!isFirstTimeLogin"
                  :ripple="false"
                  plain
                  :to="forgottenpass_route"
                  style="
                    text-transform: unset !important;
                    opacity: 1 !important;
                  "
                  >Forgotten my details</v-btn
                >
                <v-spacer></v-spacer>

                <v-btn
                  :loading="isLoading"
                  elevation="0"
                  class="text-capitalize rounded-pill white--text"
                  color="#18739d"
                  :ripple="false"
                  :disabled="isButtonDisabled"
                  @click="submit"
                >
                  <span :class="!isFirstTimeLogin ? 'px-4' : ''">
                    {{ isFirstTimeLogin ? "Create account" : "Login" }}</span
                  >
                </v-btn>
              </v-card-actions>
            </v-form>

            <v-row v-if="!isFirstTimeLogin" justify="end" class="px-1">
              <v-btn
                class="subtitle-2"
                color="#18739d"
                plain
                :to="signup_route"
                :ripple="false"
                style="
                  justify-content: end !important;
                  text-transform: unset !important;
                "
                >..or create an account</v-btn
              ></v-row
            >
            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import jwt from "jsonwebtoken";
import store from "@/store";
import FooterItem from "@/components/FooterItem.vue";

import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  components: { FooterItem },
  created() {
    const queryParams = this.$route.query;
    if (
      Object.keys(queryParams).length > 0 &&
      ((queryParams.email && !queryParams.firstLogin) ||
        (queryParams.firstLogin && !queryParams.email))
    ) {
      this.$router.push("/login");
    } else {
      this.isFirstTimeLogin = queryParams.firstLogin === "true";
      if (this.isFirstTimeLogin) {
        const correctedEmail = queryParams.email.replace(/ /g, "+");
        this.username = correctedEmail;
      }
    }
    if (this.isFirstTimeLogin) {
      //TODO
      console.log("first time login");
    } else {
      if (this.token) {
        try {
          const payload = jwt.verify(this.token, SECRET);
          let confirmPayload = {
            email: payload.email,
            password: payload.password,
          };
          this.ConfirmEmail(confirmPayload).then((resp) => {
            console.log(resp);
            if (resp.result == "OK") {
              this.isActivated = true;
            } else {
              this.showError = true;
              this.errorMsg = "Link is invalid or expired";
            }
          });
        } catch (err) {
          console.log(err);
          this.showError = true;
          this.errorMsg = "Link is invalid or expired";
        }
      }
    }
  },
  computed: {
    isButtonDisabled: function () {
      return this.isFirstTimeLogin && this.$v.$invalid;
    },
  },
  validations: {
    password: {
      required,
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return (
          containsUppercase &&
          containsLowercase &&
          containsNumber &&
          containsSpecial
        );
      },
      minLength: minLength(8),
      maxLength: maxLength(16),
    },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
  },
  data() {
    return {
      showIcon: false,
      valid: false,
      token: this.$route.params.token,
      isActivated: false,
      emailEmptyErr: "Please enter your email address",
      emailInvalidErr: "Not a valid email address",
      emailErr: "",
      invalidEmail: false,
      isFirstTimeLogin: false,
      invalidPass: false,
      showError: false,
      confirmPassword: "",
      forgottenpass_route: "/reset-password",
      errorMsg: "Email address and/or password is incorrect",
      username: "",
      password: "",
      isLoading: false,
      signup_route: "/signup",
      home_route: "/home",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    ...mapActions(["LogIn", "ConfirmEmail", "GetCurrentPlan", "SignUp"]),
    async submit() {
      this.isLoading = true;
      if (this.isFirstTimeLogin) {
        //TODO
        console.log("first time login submission");
        let payload = {
          email: this.username,
          password: this.password,
          firstLogin: true,
        };
        try {
          this.SignUp(payload)
            .then((response) => {
              console.log("ressss:", response);
              if (response && response.result == "OK") {
                this.login();
              } else {
                this.isLoading = false;
                this.showError = true;
                this.errorMsg = response.result;
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.isLoading = false;
              this.showError = true;
              this.errorMsg = "An error occurred";
            });
        } catch (err) {
          console.log("error while creating account: ", err);
          this.isLoading = false;
          this.showError = true;
          this.errorMsg = "An error occurred";
        }
      } else {
        this.validate();
        if (this.valid) {
          this.login();
        } else {
          this.isLoading = false;
          return false;
        }
      }
    },
    async login() {
      const User = new FormData();
      User.append("username", this.username);
      User.append("password", this.password);
      try {
        await this.LogIn(User);
        await this.GetCurrentPlan(store.getters.StateSessionId);
        this.showError = false;
        this.isLoading = false;

        if (this.isFirstTimeLogin) {
          this.$router.push("/plans");
        } else {
          this.$router.push("/api-keys");
        }
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
      }
    },
    validateEmail() {
      if (this.username === "") {
        this.invalidEmail = true;
        this.emailErr = this.emailEmptyErr;
        this.showError = false;
        return false;
      } else if (!this.reg.test(this.username)) {
        this.invalidEmail = true;
        this.emailErr = this.emailInvalidErr;
        this.showError = false;
        return false;
      } else {
        this.invalidEmail = false;
        return true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>