<template>
  <v-app style="background: #fafafa">
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-col cols="12">
          <v-alert
            dense
            v-if="isActivated"
            class="login-alert"
            outlined
            text
            type="success"
            >Account activated. Now you can log in.
          </v-alert>
          <v-alert
            dense
            v-if="showError"
            class="login-alert"
            outlined
            text
            type="error"
            >{{ errorMsg }}
          </v-alert>

          <v-row justify="center"> </v-row>
          <v-card elevation="0" class="login-card">
            <v-row justify="center" class="py-8"
              ><img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
            /></v-row>

            <v-form ref="form" v-model="valid">
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="username"
                  :disabled="isEmailDisabled"
                  v-model="username"
                  label="Email Address"
                  :rules="emailRules"
                  required
                  @keyup.enter="submit"
                ></v-text-field>
                <v-text-field
                  outlined
                  :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                  dense
                  :type="showIcon ? 'text' : 'password'"
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="password"
                  v-model="password"
                  @click:append="showIcon = !showIcon"
                  :label="isFirstLogin ? 'Create a Password' : 'Password'"
                  :rules="passwordRules"
                  required
                  @keyup.enter="submit"
                ></v-text-field>
                <div v-if="isFirstLogin">
                  <p
                    v-if="password && !$v.password.maxLength"
                    id="error"
                    class="text-error-message"
                  >
                    Password must be maximum 16 characters
                  </p>
                  <p
                    v-if="password && !$v.password.valid"
                    id="error"
                    class="text-error-message"
                  >
                    Your password must be 8-16 characters, and include at least
                    one lowercase letter, one uppercase letter, and a number and
                    a special character
                  </p>
                  <p
                    v-if="
                      password && $v.password.valid && !$v.password.minLength
                    "
                    id="error"
                    class="text-error-message"
                  >
                    Password must be minimum 8 characters
                  </p>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  :loading="isLoading"
                  elevation="0"
                  style="color: white"
                  :disabled="isDisabled"
                  :ripple="false"
                  class="login-button"
                  @click="submit"
                >
                  Log in
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  components: {},
  computed: {
    isDisabled() {
      if (this.isFirstLogin) {
        return this.$v.$invalid;
      }
      return false;
    },
  },
  created() {
    if (store.getters.StateRetailData != null) {
      this.$router.push("/retail-selfservice-licencelist");
    }

    const email = this.$route.query.email;
    const isFirstLogin = this.$route.query.firstLogin;
    if (email) {
      this.isEmailDisabled = true;
      this.username = email;
    }
    this.isFirstLogin = isFirstLogin;
  },
  methods: {
    ...mapActions(["RetailLogin"]),
    async submit() {
      this.isLoading = true;
      this.validate();
      if (this.valid) {
        const User = new FormData();
        User.append("username", this.username);
        User.append("password", this.password);
        try {
          const res = await this.RetailLogin(User);
          if (res) {
            if (res.result == "OK") {
              this.showError = false;
              this.isLoading = false;
              this.$router.push("/retail-selfservice-licencelist");
            } else if (res.result == "DENY") {
              this.isLoading = false;
              this.showError = true;
            }
          }
        } catch (error) {
          this.isLoading = false;
          this.showError = true;
        }
      } else {
        this.isLoading = false;
        return false;
      }
    },
    validateEmail() {
      if (this.username === "") {
        this.invalidEmail = true;
        this.emailErr = this.emailEmptyErr;
        this.showError = false;
        return false;
      } else if (!this.reg.test(this.username)) {
        this.invalidEmail = true;
        this.emailErr = this.emailInvalidErr;
        this.showError = false;
        return false;
      } else {
        this.invalidEmail = false;
        return true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  validations: {
    password: {
      required,
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return (
          containsUppercase &&
          containsLowercase &&
          containsNumber &&
          containsSpecial
        );
      },
      minLength: minLength(8),
      maxLength: maxLength(16),
    },
  },
  data() {
    return {
      showIcon: false,
      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
      valid: false,
      token: this.$route.params.token,
      isActivated: false,
      emailEmptyErr: "Please enter your email address",
      emailInvalidErr: "Not a valid email address",
      emailErr: "",
      isEmailDisabled: false,
      invalidEmail: false,
      invalidPass: false,
      showError: false,
      forgottenpass_route: "/reset-password",
      errorMsg: "Email address and/or password is incorrect",
      username: "",
      isFirstLogin: false,
      password: "",
      isLoading: false,
      signup_route: "/signup",
      home_route: "/home",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
};
</script>