<template>
  <div v-if="!isLoggedIn">
    <v-app>
      <v-container fill-height>
        <v-row justify="center" class="mt-4">
          <v-col
            :class="this.$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
            md="12"
            sm="6"
            xl="8"
            lg="11"
            xs="4"
            ><a href="https://privately.eu" target="_blank">
              <img :src="require('@/assets/images/privlogo.png')" alt="logo"
            /></a>
          </v-col>
        </v-row>
        <!-- <v-row justify="center" align="center" class="mt-4 mb-4">
          <v-col class="text-center">
            <span class="text-h4 font-weight-medium"
              >FaceAssure (On-Browser) Subscriptions</span
            >
          </v-col>
        </v-row> -->

        <v-row justify="center">
          <v-card
            width="100%"
            elevation="0"
            color="transparent"
            class="justify-center"
          >
            <v-card-title class="text-center justify-center py-2"
              ><span class="text-h5 font-weight-medium"
                >Choose your subscription:</span
              ></v-card-title
            >
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="primary"
              dark
              centered
            >
              <v-tab :key="1" class="text-capitalize black--text"
                >FaceAssure</v-tab
              >
              <v-tab :key="2" class="text-capitalize black--text" color
                ><span
                  >VoiceAssure
                  <v-icon class="black--text">mdi-information</v-icon></span
                ></v-tab
              >
              <v-tab :key="3" class="text-capitalize black--text"
                ><span
                  >Online Safety SDKs
                  <v-icon class="black--text">mdi-information</v-icon></span
                ></v-tab
              >

              <!-- <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab> -->
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              style="padding-top: 50px; background: transparent"
            >
              <!-- <v-tab-item v-for="item in items" :key="item.tab">
              <v-card flat>
                <v-card-text>{{ item.content }}</v-card-text>
              </v-card>
            </v-tab-item> -->
              <v-tab-item :key="1">
                <v-row justify="center">
                  <v-col
                    md="5"
                    lg="4"
                    xl="3"
                    xs="12"
                    sm="12"
                    v-for="(item, index) in plans"
                    :key="index"
                  >
                    <PricingPlanCard
                      :productName="item.productName"
                      :buttonName="item.buttonName"
                      :featureList="item.featureList"
                      :planValue="item.planValue"
                      :price="item.price"
                      :icons="item.icons"
                      :clickFunc="item.clickFunc"
                      :cardWidth="item.cardWidth"
                      :cardHeight="item.cardHeight"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :key="2">
                <v-row justify="center" align="center">
                  <v-col md="6" lg="6" xl="5" xs="12" sm="6">
                    <PricingPlanCard
                      :productName="card_voiceassure.productName"
                      :buttonName="card_voiceassure.buttonName"
                      :featureList="card_voiceassure.featureList"
                      :clickFunc="card_voiceassure.clickFunc"
                      :planValue="card_voiceassure.planValue"
                      :isDisabled="card_voiceassure.isDisabled"
                      :fontSize="card_voiceassure.smallFont"
                      :cardHeight="500"
                      :cardWidth="375"
                    />
                  </v-col> </v-row
              ></v-tab-item>
              <v-tab-item :key="3"
                ><v-row justify="center" align="center">
                  <v-col md="6" lg="6" xl="5" xs="12" sm="6">
                    <PricingPlanCard
                      :productName="card_owas.productName"
                      :buttonName="card_owas.buttonName"
                      :featureList="card_owas.featureList"
                      :clickFunc="card_owas.clickFunc"
                      :planValue="card_owas.planValue"
                      :isDisabled="card_owas.isDisabled"
                      :fontSize="card_owas.smallFont"
                      :cardHeight="500"
                      :cardWidth="375"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-row>
      </v-container>

      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="500"
      >
        <v-card>
          <v-toolbar color="primary" dark>Choose a plan</v-toolbar>
          <v-card-text>
            <v-card
              :ripple="false"
              v-for="(item, index) in deploymentPlanList"
              :key="index"
              outlined
              @click="onSelectDeploymentPlan(item)"
              class="rounded-0 mt-4"
              :class="item == selectedDeploymentPlan ? 'selected' : ''"
              elevation="0"
              style="cursor: pointer"
              ><v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title style="white-space: normal">
                    {{ item.title }}</v-list-item-title
                  >
                  <v-list-item-subtitle style="white-space: normal">{{
                    item.priceText
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item></v-card
            >
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="success"
              :disabled="!selectedDeploymentPlan"
              class="text-capitalize"
              @click="loggedOutCheckout"
              >Sign Up Now <v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <FooterItem />
    </v-app>
  </div>
  <base-layout current="plans" v-else>
    <v-container fill-height>
      <v-row justify="center">
        <v-card width="100%" elevation="0" color="transparent">
          <v-card-title class="text-center justify-center py-6"
            ><span class="text-h5 font-weight-medium"
              >Choose your subscription:</span
            ></v-card-title
          >
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            dark
            centered
          >
            <v-tab :key="1" class="text-capitalize black--text"
              >FaceAssure</v-tab
            >
            <v-tab :key="2" class="text-capitalize black--text" color
              ><span
                >VoiceAssure
                <v-icon class="black--text">mdi-information</v-icon></span
              ></v-tab
            >
            <v-tab :key="3" class="text-capitalize black--text"
              ><span
                >Online Safety SDKs
                <v-icon class="black--text">mdi-information</v-icon></span
              ></v-tab
            >

            <!-- <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab> -->
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            style="padding-top: 50px; background: transparent"
          >
            <!-- <v-tab-item v-for="item in items" :key="item.tab">
              <v-card flat>
                <v-card-text>{{ item.content }}</v-card-text>
              </v-card>
            </v-tab-item> -->
            <v-tab-item :key="1">
              <v-row justify="center" align="center">
                <v-col
                  md="5"
                  lg="4"
                  xl="3"
                  xs="12"
                  sm="12"
                  v-for="(item, index) in plans"
                  :key="index"
                >
                  <PricingPlanCard
                    :isLoading="item.isLoading"
                    :productName="item.productName"
                    :buttonName="item.buttonName"
                    :featureList="item.featureList"
                    :price="item.price"
                    :icons="item.icons"
                    :route="item.route"
                    :sub_detail="item.sub_detail"
                    :clickFunc="item.clickFunc"
                    :isSelected="item.isSelected"
                    :planValue="item.planValue"
                    :isDisabled="item.isDisabled"
                    :cardWidth="item.cardWidth"
                    :cardHeight="item.cardHeight"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :key="2">
              <v-row justify="center" align="center">
                <v-col md="6" lg="6" xl="5" xs="12" sm="6">
                  <PricingPlanCard
                    :productName="card_voiceassure.productName"
                    :buttonName="card_voiceassure.buttonName"
                    :featureList="card_voiceassure.featureList"
                    :clickFunc="card_voiceassure.clickFunc"
                    :planValue="card_voiceassure.planValue"
                    :isDisabled="card_voiceassure.isDisabled"
                    :fontSize="card_voiceassure.smallFont"
                    :cardHeight="500"
                    :cardWidth="375"
                  />
                </v-col> </v-row
            ></v-tab-item>
            <v-tab-item :key="3"
              ><v-row justify="center" align="center">
                <v-col md="6" lg="6" xl="5" xs="12" sm="6">
                  <PricingPlanCard
                    :productName="card_owas.productName"
                    :buttonName="card_owas.buttonName"
                    :featureList="card_owas.featureList"
                    :clickFunc="card_owas.clickFunc"
                    :planValue="card_owas.planValue"
                    :isDisabled="card_owas.isDisabled"
                    :fontSize="card_owas.smallFont"
                    :cardHeight="500"
                    :cardWidth="375"
                  />
                </v-col> </v-row
            ></v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card :loading="deploymentLoading">
        <v-toolbar color="primary" dark
          ><span style="font-weight: bold"> Choose a plan</span></v-toolbar
        >
        <v-card-text>
          <v-card
            @click="onSelectDeploymentPlan(item)"
            :ripple="false"
            v-for="(item, index) in deploymentPlanList"
            :key="index"
            outlined
            :disabled="currentlySelectedPlan == item.code && !cancelled_at"
            class="rounded-0 mt-4"
            :class="item == selectedDeploymentPlan ? 'selected' : ''"
            elevation="0"
            style="cursor: pointer"
            ><v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title style="white-space: normal">
                  {{ item.title }}</v-list-item-title
                >
                <v-list-item-subtitle style="white-space: normal">{{
                  item.priceText
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item></v-card
          >
          <v-list-item>
            <v-list-item-content>
              <v-divider class="pa-2 mt-4"></v-divider>
              <v-list-item-title style="white-space: normal; font-weight: bold">
                Preferred Currency:</v-list-item-title
              >
              <v-list-item-subtitle class="py-2" style="white-space: normal">
                <v-row justify="center" align="center" class="pa-4">
                  <v-col
                    cols="4"
                    style="cursor: pointer"
                    class="text-center font-weight-bold"
                    @click="preferred_currency = 'GBP'"
                    :class="preferred_currency == 'GBP' ? 'selected' : ''"
                    >Pound Sterling (GBP)</v-col
                  >
                  <v-col
                    cols="4"
                    class="text-center font-weight-bold"
                    style="cursor: pointer"
                    @click="preferred_currency = 'USD'"
                    :class="preferred_currency == 'USD' ? 'selected' : ''"
                    >US Dollar (USD)</v-col
                  >
                  <v-col
                    cols="4"
                    class="text-center font-weight-bold"
                    style="cursor: pointer"
                    @click="preferred_currency = 'EUR'"
                    :class="preferred_currency == 'EUR' ? 'selected' : ''"
                    >Euro (EUR)</v-col
                  >
                </v-row></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            :disabled="!selectedDeploymentPlan"
            class="text-capitalize"
            @click="checkout"
            >Proceed to checkout <v-icon>mdi-chevron-right</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-layout>
</template>

<script>
import { mapActions } from "vuex";
import FooterItem from "@/components/FooterItem.vue";
import PricingPlanCard from "@/components/cards/PricingPlanCard.vue";
import store from "@/store";
import BaseLayout from "@/layouts/BaseLayout.vue";

export default {
  methods: {
    ...mapActions(["DevChannelSubscription"]),
    onSelectDeploymentPlan(item) {
      this.selectedDeploymentPlan = item;
    },
    checkout() {
      const loggedInEmail = store.getters.StateUser;

      this.deploymentLoading = true;
      const payload = {
        subscription_settings: {
          preferred_currency: this.preferred_currency,
          selected_plan: this.selectedDeploymentPlan.code,
          current_stripe_subscription_id: this.currentStripeSubscriptionId,
          baseDomain: this.baseDomain,
          env: this.baseDomain.includes("localhost") ? "test" : "prod",
        },
        session_id: this.apiKey,
        email: loggedInEmail,
      };
      this.deploymentLoading = true;

      this.DevChannelSubscription(payload).then((res) => {
        if (res.success) {
          this.deploymentLoading = false;
          window.location.href = res.checkoutSession.url;
        }
      });
    },
    loggedOutCheckout() {
      this.$router.push("/signup");
    },
  },
  components: { FooterItem, PricingPlanCard, BaseLayout },
  computed: {},
  beforeMount() {
    this.userData = store.getters.StateUserData;
    this.apiKey = store.getters.StateSessionId;
    this.isLoggedIn = store.getters.isAuthenticated;
    const sandboxPlan = this.plans[0];
    const deploymentPlan = this.plans[1];
    const enterprisePlan = this.plans[2];
    const owasPlan = this.card_owas;
    const voiceAssurePlan = this.card_voiceassure;

    enterprisePlan["clickFunc"] = () => {
      window.open(
        "mailto:dev_channel_contact@privately.eu?subject=Inquiry about Enterprise/Mobile SDKs",
        "_blank" // <- This is what makes it open in a new window.
      );
    };

    owasPlan["clickFunc"] = () => {
      window.open(
        "mailto:dev_channel_contact@privately.eu?subject=Inquiry about OWAS SDKs",
        "_blank"
      );
    };

    voiceAssurePlan["clickFunc"] = () => {
      window.open(
        "mailto:dev_channel_contact@privately.eu?subject=Inquiry about VoiceAssure",
        "_blank"
      );
    };

    if (!this.isLoggedIn) {
      sandboxPlan["clickFunc"] = () => {
        this.$router.push("/signup");
      };

      deploymentPlan["clickFunc"] = () => {
        // this.$router.push("/signup");
        this.dialog = true;
      };
    } else {
      this.additional_info_fields = this.userData["additional_info_fields"];

      const loggedInEmail = store.getters.StateUser;

      this.cancelled_at = this.additional_info_fields["cancelled_at"];

      const selectedPlan = this.additional_info_fields
        ? this.additional_info_fields["selected_plan"]
        : null;

      this.currentStripeSubscriptionId = this.additional_info_fields
        ? this.additional_info_fields["stripe_subscription_id"]
        : null;

      this.currentlySelectedPlan = selectedPlan;

      this.plans.forEach((plan) => {
        plan.isSelected =
          selectedPlan && selectedPlan.startsWith(plan.planValue);

        if (plan.planValue === "sandbox") {
          if (this.cancelled_at === undefined) {
            if (plan.isSelected) {
              plan.buttonName = "Current Plan";
              plan.isDisabled = true;
            }
          } else {
            plan.buttonName = "Unavailable";
            plan.isDisabled = true;
          }
        }

        if (plan.planValue.startsWith("deployment")) {
          if (plan.isSelected) {
            plan.buttonName =
              this.currentlySelectedPlan == "deployment_10000"
                ? "Downgrade Plan"
                : "Upgrade Plan";
            plan.isDisabled = false;

            const sandboxPlan = this.plans.find(
              (p) => p.planValue === "sandbox"
            );
            if (sandboxPlan) {
              sandboxPlan.buttonName = "Unavailable";
              sandboxPlan.isDisabled = true;
            }

            if (this.cancelled_at) {
              plan.buttonName = "Buy it now";
              plan.isDisabled = false;
            }
          }
        }
      });

      sandboxPlan["clickFunc"] = () => {
        this.$set(sandboxPlan, "isLoading", true);

        const payload = {
          subscription_settings: {
            selected_plan: "sandbox",
            baseDomain: this.baseDomain,
            env: this.baseDomain.includes("localhost") ? "test" : "prod",
          },
          session_id: this.apiKey,
          email: loggedInEmail,
        };
        this.DevChannelSubscription(payload).then((res) => {
          if (res.success) {
            this.$router.push("/api-keys");
          }
        });
      };

      // deploymentPlan["clickFunc"] = () => {
      //   this.$set(deploymentPlan, "isLoading", true);

      //   const payload = {
      //     subscription_settings: {
      //       selected_plan: "deployment",
      //       baseDomain: this.baseDomain,
      //       env: this.baseDomain.includes("localhost") ? "test" : "prod",
      //     },
      //     session_id: this.apiKey,
      //     email: loggedInEmail,
      //   };
      //   this.DevChannelSubscription(payload).then((res) => {
      //     deploymentPlan["isLoading"] = true;
      //     if (res.success) {
      //       this.$set(deploymentPlan, "isLoading", false);
      //       window.location.href = res.checkoutSession.url;
      //     }
      //   });
      // };
      //todo
      deploymentPlan["clickFunc"] = () => {
        this.dialog = true;
      };
    }
  },
  data: () => ({
    additional_info_fields: null,
    isLoggedIn: false,
    userData: null,
    has_subscription: false,
    selected_plan: null,
    preferred_currency: "GBP",
    baseDomain: window.location.origin,
    dialog: false,
    deploymentLoading: false,
    tab: null,
    selectedDeploymentPlan: null,
    deploymentPlanList: [
      {
        code: "deployment_1000",
        title: "1000 age checks / month",
        priceText: "£200 / month",
      },
      {
        code: "deployment_2500",
        title: "2500 age checks / month",
        priceText: "£500 / month",
      },
      {
        code: "deployment_5000",
        title: "5000 age checks / month",
        priceText: "£1000 / month",
      },
      {
        code: "deployment_10000",
        title: "10000 age checks / month",
        priceText: "£2000 / month",
      },
    ],
    card_voiceassure: {
      cardHeight: "375px",
      cardWidth: "500px",
      buttonName: "Contact us",
      smallFont: true,
      productName:
        "VoiceAssure elevates voice analysis, confidently estimating age ranges (0-12, 13-17, 18-24, 25+) and shielding your platform from synthetic voices. No more spoofing worries. Advanced algorithms analyse multiple vocal checks for precise, adaptable results. Unreliable audio? Rejected instantly. Build trusted voice experiences with VoiceAssure - secure, accurate, and always vigilant.",
    },
    card_owas: {
      buttonName: "Contact us",
      cardHeight: "375px",
      cardWidth: "500px",
      smallFont: true,
      productName:
        "Keep your online platforms safe and welcoming with Privately's Online Safety tools. Our tech detects harmful content like hate speech and nudity without ever touching user data - everything stays private on their device. We even understand emotions in messages to keep things positive. Make everyone feel safe and happy online, the smart way.",
    },
    plans: [
      {
        planValue: "sandbox",
        cardWidth: "290px",
        isSelected: false,
        isDisabled: false,
        currentStripeSubscriptionId: null,
        //route: "/signup",
        isLoading: false,
        cancelled_at: null,
        clickFunc: () => {},
        productName: "Sandbox - Test",
        price: "Free (On-browser)",
        buttonName: "Try for free",
        featureList: [
          { text: "100 Age checks", desc: "4 week trial period" },
          { text: "Standard age brackets", desc: "<16, <25, 25+" },
          {
            text: "Liveness Check / Anti Spoofing",
            desc: "",
            unavailable: true,
          },
          {
            text: "On Device-Processing",
            desc: "Images might be backed up on server",
            unavailable: true,
          },
          {
            text: "Email Support",
          },
        ],
      },
      {
        //route: "/signup",
        planValue: "deployment",
        cardWidth: "290px",
        isSelected: false,
        isDisabled: false,
        cancelled_at: null,
        currentlySelectedPlan: null,
        isCancelLoading: false,
        isLoading: false,
        clickFunc: () => {},
        productName: "Deployment",
        price: "Starting at £200 / Month",
        buttonName: "Buy now",
        featureList: [
          { text: "Up to 10000 age checks", desc: "per month" },
          { text: "Standard age brackets", desc: "<16, <25, 25+" },
          {
            text: "Liveness Check / Anti Spoofing",
            desc: "",
          },
          {
            text: "On Device-Processing",
            desc: "Guaranteed data privacy",
          },
          {
            text: "Email Support",
          },
        ],
      },
      {
        planValue: "enterprise",
        cardWidth: "290px",

        isSelected: false,
        isDisabled: false,
        isLoading: false,
        productName: "Enterprise /",
        target:
          "mailto:dev_channel_contact@privately.eu?subject=Inquiry about Enterprise/Mobile SDKs",
        icons: true,
        buttonName: "Contact us",
        featureList: [
          { text: "Scalable age checks", desc: "Up to 500000" },
          { text: "Custom age brackets", desc: "<16, <25, 25+, or custom" },
          {
            text: "Liveness Check / Anti Spoofing",
          },
          {
            text: "On Device-Processing",
            desc: "Guaranteed data privacy",
          },
          {
            text: "Email Support",
          },
        ],
      },
    ],
  }),
  mounted() {},
  beforeDestroy() {},
};
</script>
<style scoped>
.selected {
  border: 2px solid #18739d;
}
</style>